<template>
    <div class="add-new-travelling-order">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.stockItemOwnerUpdate')"
            :title="$t('messages.stockItemOwnerUpdate')"
            @submitPressed="submitPressed"
        />
        <stock-item-owner-form v-if="owner" :ownerObject="owner" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import StockItemOwnerForm from './components/StockItemOwnerForm.vue'

export default {
    components: {
        TitleBar,
        StockItemOwnerForm,
    },
    data() {
        return {
            owner: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$StockItemOwners.getResource({id}).then((response) => {
                this.owner = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
